import { ReactElement } from 'react'
import { Trans } from '@lingui/macro'
import { Banner } from '../../lib/components/banner'
import { Link } from '../../lib/components/link'
import { UseHistoryStateType } from '../artist-discography'
import { DidYouMeanBannerContent } from '../../lib/components/did-you-mean-banner/did-you-mean-banner'

type Props = {
    historyState?: UseHistoryStateType | undefined
    discogsId?: number
    doesNotExist?: boolean
}

export function LabelBanner(props: Props): ReactElement | null {
    const { historyState, discogsId = 0, doesNotExist = false } = props

    const idBasedContent = {}

    const { bannerText, display } = DidYouMeanBannerContent()

    if (display) {
        return <Banner>{bannerText}</Banner>
    }

    if (discogsId in idBasedContent) {
        return <Banner>{idBasedContent[discogsId as keyof typeof idBasedContent]}</Banner>
    }

    if (doesNotExist) {
        return (
            <Banner>
                <Trans>Sorry, this Label does not have any Releases under that name.</Trans>
            </Banner>
        )
    }

    if (!historyState?.name) {
        return null
    }

    return (
        <Banner>
            <Trans>
                The Label {historyState.name} is invalid. Please refer to this Label instead. View{' '}
                <Link href={`${historyState.url}/history#latest`}>{historyState.name}&apos;s edit history</Link>.
            </Trans>
        </Banner>
    )
}
