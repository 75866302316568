import { ReactElement } from 'react'

import { Trans, t } from '@lingui/macro'

import { LabelImagesFragment } from '../../api/types'
import { Link } from '../../lib/components/link'
import { NSFWImagePlaceholderSmall } from '../../lib/components/nsfw-image-placeholder'
import { LabelThumbnail } from '../label-thumbnail'
import { GalleryLink } from '../image-gallery/link'
import { useLocalisation } from '../../lib/i18n'
import { useLogin } from '../../lib/login'

import css from './styles.css'

type Props = LabelImagesFragment

export function LabelImages(props: Props): ReactElement {
    const { loggedIn } = useLogin()
    const { discogsId, name, images } = props
    const { i18n } = useLocalisation()

    const image = images.edges[0]?.node
    const alt = image ? t(i18n)`${name} image` : t(i18n)`No image available; add an image`

    if (!image) {
        const add = `/label/${discogsId}/images/update`

        return (
            <div>
                <Link href={add}>
                    <LabelThumbnail alt={alt} />
                </Link>
                <p className={css.add}>
                    <Link href={add}>
                        <Trans>Add An Image</Trans>
                    </Link>
                </p>
            </div>
        )
    }

    if (image.nsfw && !loggedIn) {
        return (
            <div>
                <GalleryLink imageId={image.id} className={css.thumbnail}>
                    <NSFWImagePlaceholderSmall showButton />
                    {images.totalCount > 1 && (
                        <p className={css.moreImages}>
                            <Trans>More images</Trans>
                        </p>
                    )}
                </GalleryLink>
            </div>
        )
    }

    return (
        <div className={css.more}>
            <GalleryLink imageId={image.id} className={css.thumbnail}>
                <LabelThumbnail image={image.thumbnail} alt={alt} preload lazy={false} />
                {images.totalCount > 1 && (
                    <p>
                        <Trans>More images</Trans>
                    </p>
                )}
            </GalleryLink>
        </div>
    )
}
