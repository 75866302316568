import { ReactElement } from 'react'
import { Trans } from '@lingui/macro'
import css from './styles.css'

import {
    ReleaseDataQuality as ReleaseDataQualityT,
    ReleaseVisibility,
    LabelVisibility,
    DataQuality,
} from '../../api/types'

const labels = {
    [ReleaseDataQualityT.NewSubmission]: <Trans>New Submission</Trans>,
    [ReleaseDataQualityT.NeedsChanges]: <Trans>Needs Changes</Trans>,
    [ReleaseDataQualityT.ReviewChanges]: <Trans>Recently Edited</Trans>,
    [`release-${ReleaseDataQualityT.Correct}`]: <Trans>Data Correct</Trans>,
    [DataQuality.CompleteAndCorrect]: <Trans>Complete and correct</Trans>,
    [`data-${DataQuality.Correct}`]: <Trans>Correct</Trans>,
    [DataQuality.NeedsMajorChanges]: <Trans>Needs major changes</Trans>,
    [DataQuality.NeedsMinorChanges]: <Trans>Needs minor changes</Trans>,
    [DataQuality.NeedsVote]: <Trans>Needs Vote</Trans>,
    [DataQuality.EntirelyIncorrect]: <Trans>Entirely Incorrect</Trans>,
    [DataQuality.Unknown]: <Trans>Unknown</Trans>,
}

type ReleaseDataQualityProps = {
    dataQuality: ReleaseDataQualityT
    visibility: ReleaseVisibility
}

export function ReleaseDataQuality(props: ReleaseDataQualityProps): ReactElement {
    const { dataQuality, visibility } = props

    if (visibility === ReleaseVisibility.Unlisted) {
        return <Trans>Draft</Trans>
    }
    let labelKey = null

    switch (dataQuality) {
        case ReleaseDataQualityT.Correct:
            labelKey = `release-${dataQuality}`
            break
        case DataQuality.Correct:
            labelKey = `data-${dataQuality}`
            break
        default:
            labelKey = dataQuality
    }

    return labels[labelKey]
}

type LabelDataQualityProps = {
    dataQuality: DataQuality
    visibility: LabelVisibility
}

export function LabelDataQuality(props: LabelDataQualityProps): ReactElement {
    const { dataQuality, visibility } = props

    if (visibility === LabelVisibility.Hidden) {
        return <Trans>Draft</Trans>
    }

    let labelKey = null

    switch (dataQuality) {
        case ReleaseDataQualityT.Correct:
            labelKey = `release-${dataQuality}`
            break
        case DataQuality.Correct:
            labelKey = `data-${dataQuality}`
            break
        default:
            labelKey = dataQuality
    }

    return (
        <>
            <Trans>Data quality rating: </Trans> <span className={css.dataQuality}>{labels[labelKey]}</span>
        </>
    )
}
