/* eslint-disable react/no-array-index-key */
import { ReactElement, useState, useRef, useEffect } from 'react'
import classnames from 'classnames'
import { Trans, t } from '@lingui/macro'
import {
    useLabelReleasesQuery,
    ItemType,
    LabelReleasesFragment,
    LabelCreditsConnection,
    LabelReleaseGroupPaginationInput,
    OrderByDirection,
    LabelReleaseGroupOrderByField,
} from '../../api/types'
import { useLocalisation } from '../../lib/i18n'
import { getFacets } from '../artist-discography/helpers'
import { FacetFilterMobile } from '../../lib/components/facetFilters/mobileUI'

import { useHistory, useLocation } from 'react-router'

import { View, ViewSetting } from '../../lib/components/view'
import { Progressbar } from '../../lib/components/progressbar'

import { set, useQueryParam } from '../../lib/query'
import { usePersist } from '../../lib/use-persist'
import { Link } from '../../lib/components/link'
import { ChevronDown, Plus } from '../../lib/components/icon'
import { Dispatcher, WithProps, addState } from '../../lib/add-state'
import { FilterButton } from '../../lib/components/filterButton/filterButton'
import { FacetFilter, FacetObjects } from '../../lib/components/facetFilters'

import { ReleaseCard } from '../../components/release-card'
import { ArtistVersionTableRender as ReleaseTable } from '../artist-views/ui'

import { Pager } from '@discogs/amped/pager'
import css from './styles.css'
import { safeGetUrlParams } from '../../lib/components/facetFilters/utils'

import { safeGetItem, safeRemoveItem, safeSetItem } from '../../lib/components/pagination/paginationUtils'
import { removeTypename } from '../../lib/remove-typename'

type FacetsObj = {
    format: FacetObjects | null
    genre: FacetObjects | null
    country: FacetObjects | null
    year: FacetObjects | null
    role: FacetObjects | null
}

type Props = {
    discogsId: number
    name: string
    headerIsExpanded: boolean
}

export type State = {
    page: number
    loading: boolean
    data: { label?: LabelReleasesFragment } | null
    prevData: { label?: LabelReleasesFragment } | null | undefined
    perPage: number
    labelPages: LabelReleaseGroupPaginationInput['labelPages']
    order: string
    search: string
    view: ViewSetting
    formats: string[]
    genres: string[]
    years: number[]
    roles: string[]
    countries: string[]
    formatFacets: FacetObjects | null
    genreFacets: FacetObjects | null
    countryFacets: FacetObjects | null
    yearFacets: FacetObjects | null
    roleFacets: FacetObjects | null
    creditCategoryTotals: LabelCreditsConnection['labelCreditFacets']
    advancedFilterSelected: { [key: string]: boolean }
    advancedFilterShowState: {
        desktop: boolean | null
        mobile: boolean
    }
}

export type Action =
    | {
          type: 'data'
          data: { label?: LabelReleasesFragment } | null
          prevData: { label?: LabelReleasesFragment } | null | undefined
      }
    | { type: 'page'; page: number }
    | { type: 'per-page'; perPage: number }
    | { type: 'pagination'; page: number; perPage: number; labelPages: LabelReleaseGroupPaginationInput['labelPages'] }
    | { type: 'sortBy'; order: string }
    | { type: 'view'; view: ViewSetting }
    | { type: 'search'; search: string }
    | {
          type: 'advancedFiltersApplied'
          formats: string[]
          genres: string[]
          countries: string[]
          years: number[]
          roles: string[]
      }
    | {
          type: 'advancedFilterSelected'
          advancedFilterSelected: { [key: string]: boolean }
          advancedFilterShowState: {
              desktop: boolean | null
              mobile: boolean
          }
      }

export type UIProps = WithProps<Props, State, Action>

export function reducer(props: Props, state: State, action: Action): State {
    switch (action.type) {
        case 'data': {
            if (action.data?.label) {
                // @ts-expect-error - TODO return to type this correctly
                const advancedFilterFacetData = getFacets(action.data.label.releases.labelCreditFacets)
                return {
                    ...state,
                    loading: false,
                    data: action.data,
                    prevData: action.prevData,
                    formatFacets: advancedFilterFacetData.format,
                    yearFacets: advancedFilterFacetData.year,
                    genreFacets: advancedFilterFacetData.genre,
                    countryFacets: advancedFilterFacetData.country,
                    roleFacets: advancedFilterFacetData.credits,
                }
            }
            return {
                ...state,
                data: action.data,
                prevData: action.prevData,
            }
        }
        case 'page': {
            return {
                ...state,
                page: action.page,
                loading: true,
            }
        }
        case 'pagination': {
            return {
                ...state,
                page: action.page,
                perPage: action.perPage,
                labelPages: action.labelPages,
                loading: true,
            }
        }
        case 'per-page':
            return {
                ...state,
                perPage: action.perPage,
                page: action.perPage === state.perPage ? state.page : 1,
                loading: true,
            }
        case 'sortBy': {
            const updated = action.order !== state.order
            return {
                ...state,
                order: action.order,
                page: updated ? 1 : state.page,
                loading: true,
            }
        }
        case 'view':
            return {
                ...state,
                view: action.view,
            }
        case 'search': {
            return {
                ...state,
                search: action.search,
                page: 1,
                loading: true,
            }
        }
        case 'advancedFiltersApplied': {
            return {
                ...state,
                formats: action.formats,
                years: action.years,
                countries: action.countries,
                genres: action.genres,
                roles: action.roles,
                loading: true,
                page: 1,
            }
        }
        case 'advancedFilterSelected':
            return {
                ...state,
                advancedFilterSelected: action.advancedFilterSelected,
                advancedFilterShowState: action.advancedFilterShowState,
            }
        default:
            return state
    }
}

function useInitial(): State {
    const [pageNumber] = useQueryParam('page')
    const [showNumber] = usePersist<number>('label_page_show_number')
    const [persistedView] = usePersist<State['view']>('label_page_view')
    const [sortOrder] = usePersist<State['order']>('label_page_sort_order')
    const [searchParam] = useQueryParam('searchParam')
    const labelPagesInitialData = safeGetItem('labelPagesData')
    const urlParams = safeGetUrlParams()

    function useSessionLabelData(jsonString: string | null | undefined, pageNumber: number): [] {
        const vfFiltersInUrl =
            [
                ...urlParams.formatParam,
                ...urlParams.genreParam,
                ...urlParams.roleParam,
                ...urlParams.countryParams,
                ...urlParams.yearParams,
            ].length > 0

        if (!jsonString) {
            return []
        }
        if (pageNumber === 0 || vfFiltersInUrl) {
            safeRemoveItem('labelPagesData')
            return []
        }
        try {
            const parsedData = JSON.parse(jsonString)
            return parsedData
        } catch (error) {
            return []
        }
    }

    return {
        loading: true,
        data: null,
        formatFacets: null,
        yearFacets: null,
        countryFacets: null,
        genreFacets: null,
        roleFacets: null,
        formats: urlParams.formatParam,
        years: urlParams.yearParams,
        countries: urlParams.countryParams,
        genres: urlParams.genreParam,
        roles: urlParams.roleParam,
        creditCategoryTotals: undefined,
        prevData: null,
        // pull page from url if there is a param otherwise default to page 1
        page: Number(pageNumber) === 0 ? 1 : Number(pageNumber),
        perPage: typeof showNumber === 'number' ? showNumber : 25,
        order: sortOrder ? sortOrder : 'CATNO ASC',
        view: persistedView ? persistedView : 'textWithCovers',
        search: searchParam ?? '',
        advancedFilterSelected: urlParams.allParamsFromUrl,
        advancedFilterShowState: { desktop: null, mobile: false },
        labelPages: useSessionLabelData(labelPagesInitialData, Number(pageNumber)),
    }
}

function useEffects(props: Props, state: State, dispatch: Dispatcher<Action>): void {
    const {
        perPage,
        view,
        order,
        page,
        search,
        years,
        genres,
        formats,
        countries,
        roles,
        advancedFilterShowState,
        advancedFilterSelected,
        labelPages,
    } = state
    const { discogsId } = props

    const orderBy = {
        field: order.split(' ')[0],
        direction: order.split(' ')[1],
    }

    const [pageNumber] = useQueryParam('page')

    const yearsSelected = years.map(Number)
    const labelPagesData = labelPages ? (labelPages.length > 0 ? labelPages : []) : []
    const pageNumberFromUrl = pageNumber ? Number(pageNumber) : page

    const res = useLabelReleasesQuery({
        ssr: true,
        variables: {
            discogsId,
            page: pageNumberFromUrl,
            perPage,
            labelPages: labelPagesData,
            creditCategories: roles,
            countries,
            formats,
            genres,
            years: yearsSelected,
            orderBy: {
                field: orderBy.field as LabelReleaseGroupOrderByField,
                direction: orderBy.direction as OrderByDirection,
            },
            search,
        },
    })

    if (res.error) {
        throw res.error
    }

    const data = res.loading ? res.previousData : res.data

    if (!data?.label && !res.loading && !res.previousData) {
        throw new Error('Label not found')
    }

    useEffect(
        function () {
            if (data) {
                dispatch({
                    type: 'data',
                    data,
                    prevData: res.previousData,
                })
            }
        },
        [data],
    )

    const [showNumber, setShowNumber] = usePersist<number>('label_page_show_number')

    useEffect(
        function () {
            if (typeof perPage === 'number' && showNumber !== perPage) {
                setShowNumber(perPage)
            }
        },
        [perPage],
    )

    const [, setPersistedView] = usePersist<State['view']>('label_page_view')

    useEffect(
        function (): void {
            setPersistedView(view)
        },
        [view],
    )

    const [, setSortOrder] = usePersist<State['order']>('label_page_sort_order')
    useEffect(
        function () {
            setSortOrder(order)
        },
        [order],
    )

    const [showFilters, setShowFilters] = usePersist<boolean>('label_page_filters')

    useEffect(
        function () {
            if (advancedFilterShowState.desktop !== null) {
                setShowFilters(advancedFilterShowState.desktop)
            }
        },
        [advancedFilterShowState.desktop],
    )

    useEffect(
        function () {
            if (res.data?.label?.releases) {
                dispatch({
                    type: 'advancedFilterSelected',
                    advancedFilterSelected,
                    advancedFilterShowState: {
                        desktop: showFilters === false ? showFilters : true,
                        mobile: false,
                    },
                })
            }
        },
        [res.data?.label?.releases],
    )

    useEffect(
        function () {
            safeSetItem('labelPagesData', JSON.stringify(labelPages))
        },
        [labelPages],
    )
}

export const LabelReleases = addState(LabelReleasesUI, reducer, useInitial, useEffects)

export function LabelReleasesUI(props: Props & UIProps): ReactElement {
    const {
        discogsId,
        name,
        data,
        prevData,
        loading,
        page,
        perPage,
        order,
        dispatch,
        formatFacets,
        countryFacets,
        yearFacets,
        genreFacets,
        roleFacets,
        roles,
        countries,
        formats,
        genres,
        years,
        search,
        advancedFilterSelected,
        advancedFilterShowState,
        headerIsExpanded,
        view,
    } = props

    const location = useLocation()
    const history = useHistory()
    const { i18n } = useLocalisation()

    const scrollToTop = useRef<HTMLDivElement>(null)

    const label = data?.label ? data.label : prevData?.label

    const totalCount = label?.releases.labelCreditFacets?.find(
        (facet) => facet?.superFacetName === 'credit_category',
    )?.totalCount

    // Role counts do not live in the same place as other filter counts.
    // When the user filters by a non role filter facet.credit_category.totalCount
    // updates. When they select a role filter it does not update. In this case we
    // need to retrieve the count from the appropriate roles facetDetails.totalCount

    const totalRoleCount = roleFacets
        ? roles.reduce((sum: number, key: string) => sum + (roleFacets[key].totalCount || 0), 0)
        : 0

    const orderBy = {
        field: order.split(' ')[0],
        direction: order.split(' ')[1],
    }

    const [arrow, setArrow] = useState(orderBy.direction === 'ASC')

    function updateSort(sortData: string) {
        dispatch({ type: 'sortBy', order: sortData })
        dispatch({
            type: 'pagination',
            page: 1,
            perPage,
            labelPages: [],
        })
        history.push(
            set(location, {
                page: null,
            }),
        )
    }

    useEffect(
        function () {
            setArrow(!arrow)
        },
        [orderBy.direction],
    )

    function handleViewChange(selection: ViewSetting) {
        dispatch({ type: 'view', view: selection })
    }

    function handlePerPageChange(perPage: number) {
        dispatch({ type: 'pagination', page: 1, perPage, labelPages: [] })
        history.push(
            set(location, {
                page: null,
            }),
        )
        safeRemoveItem('labelPagesData')
    }

    function handlePageChange(newPage: number, page: number) {
        const paginationInfo = removeTypename(data?.label?.releases.releaseGroups.paginationInfo)
        if (page > newPage) {
            dispatch({
                type: 'pagination',
                page,
                perPage,
                labelPages: paginationInfo,
            })
            history.push(
                set(location, {
                    page,
                }),
            )
        } else {
            dispatch({
                type: 'pagination',
                page,
                perPage,
                labelPages: paginationInfo,
            })
            history.push(
                set(location, {
                    page,
                }),
            )
        }

        if (scrollToTop.current) {
            const inView = scrollToTop.current.getBoundingClientRect()

            const isInView =
                inView.top >= -inView.height &&
                inView.bottom <= (window.innerHeight || document.documentElement.clientHeight) + inView.height

            if (!isInView) {
                scrollToTop.current.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' })
            }
        }
    }

    const searchResultCount = label?.releases.releaseGroups.releaseGroupDescriptions?.length

    const facetsObject = {
        format: formatFacets,
        genre: genreFacets,
        country: countryFacets,
        year: yearFacets,
        role: roleFacets,
    }

    // this is temporary code until Susan has time to remove 0 count filter entries on the BE.

    function removeEntriesWithZeroCount(obj: FacetsObj): FacetsObj {
        const filterFacets = (facets: FacetObjects | null): FacetObjects | null => {
            if (!facets) {
                return null
            }
            const filteredEntries = Object.entries(facets).reduce(function (acc, [key, value]) {
                if (value.totalCount > 0) {
                    acc[key] = value
                }
                return acc
            }, {} as FacetObjects)
            return Object.keys(filteredEntries).length > 0 ? filteredEntries : null
        }

        return {
            format: filterFacets(obj.format),
            genre: filterFacets(obj.genre),
            role: filterFacets(obj.role),
            country: filterFacets(obj.country),
            year: filterFacets(obj.year),
        }
    }

    return (
        <div>
            {advancedFilterShowState.desktop && (
                <FacetFilter
                    data={removeEntriesWithZeroCount(facetsObject)}
                    // TODO return to type this correctly - Inside the filters we are narrowly
                    // typing this to correspond to the actions we use but outside it is typed
                    // to accept all the actions we use.
                    // @ts-expect-error
                    dispatch={dispatch}
                    yearSelected={years}
                    formatSelected={formats}
                    countrySelected={countries}
                    genreSelected={genres}
                    roleSelected={roles}
                    advancedFilterSelected={advancedFilterSelected}
                    advancedFilterShowState={advancedFilterShowState}
                    loading={loading}
                    search={search}
                    inputValues={['Find a format', 'Find a genre', 'Find a role', 'Find a country', 'Find a year']}
                />
            )}

            <FacetFilterMobile
                data={removeEntriesWithZeroCount(facetsObject)}
                // TODO return to type this correctly - Inside the filters we are narrowly
                // typing this to correspond to the actions we use but outside it is typed
                // to accept all the actions we use.
                // @ts-expect-error
                dispatch={dispatch}
                yearSelected={years}
                formatSelected={formats}
                countrySelected={countries}
                genreSelected={genres}
                roleSelected={roles}
                advancedFilterSelected={advancedFilterSelected}
                advancedFilterShowState={advancedFilterShowState}
                search={search}
                headerIsExpanded={headerIsExpanded}
                inputValues={['Format', 'Genres', 'Role', 'Find a country', 'Year', 'Role']}
            />

            <div className={classnames(css.root, css[view])}>
                <div className={css.header} ref={scrollToTop}>
                    {totalCount && (
                        <div className={css.pagerTop}>
                            <Pager
                                currentPage={page}
                                perPage={perPage}
                                totalCount={totalRoleCount > 0 ? totalRoleCount : totalCount}
                                onChange={(newPage: number) => handlePageChange(page, newPage)}
                                title={i18n._(t`Label releases top pagination`)}
                            />
                        </div>
                    )}
                    <div className={css.fill} />
                    <div className={classnames(css.filters, loading && css.loading)}>
                        <FilterButton
                            loading={loading}
                            advancedFilterSelected={advancedFilterSelected}
                            advancedFilterShowState={advancedFilterShowState}
                            dispatch={dispatch}
                        />
                        <span className={css.divider} />

                        <View view={view} setView={handleViewChange} />
                    </div>
                    <div className={css.twoMobile}>
                        <div className={css.pager}>
                            {totalCount && (
                                <Pager
                                    currentPage={page}
                                    perPage={perPage}
                                    totalCount={totalRoleCount > 0 ? totalRoleCount : totalCount}
                                    onChange={(newPage: number) => handlePageChange(page, newPage)}
                                    title={i18n._(t`Label releases bottom pagination`)}
                                />
                            )}
                        </div>
                        <div className={classnames(css.SortAndView, loading && css.loading)}>
                            <span className={css.mobileSortContainer}>
                                <span
                                    onClick={() => updateSort(`YEAR ${orderBy.direction === 'DESC' ? 'ASC' : 'DESC'}`)}
                                    role='button'
                                >
                                    <Trans>Year</Trans>
                                    {orderBy.field === 'YEAR' && (
                                        <ChevronDown
                                            className={classnames(css.sort, orderBy.direction === 'DESC' && css.desc)}
                                        />
                                    )}
                                </span>
                            </span>
                            <View setView={handleViewChange} view={view} />
                        </div>
                    </div>
                    <div className={classnames(css.showContainer, loading && css.loading)}>
                        <label htmlFor='show'>
                            <Trans>Show</Trans>
                        </label>
                        <select
                            name='show'
                            id='show'
                            value={perPage ? perPage : 25}
                            onChange={(e) => handlePerPageChange(parseInt(e.target.value, 10))}
                        >
                            <option value={25}>{t(i18n)`25`}</option>
                            <option value={50}>{t(i18n)`50`}</option>
                            <option value={100}>{t(i18n)`100`}</option>
                            <option value={250}>{t(i18n)`250`}</option>
                            <option value={500}>{t(i18n)`500`}</option>
                        </select>
                    </div>
                </div>
                {!advancedFilterShowState.desktop && (
                    <div className={css.progress}>
                        <Progressbar loading={Boolean(loading)} aria-label={i18n._(t`Loading releases`)} />
                    </div>
                )}
                {searchResultCount || searchResultCount === undefined ? (
                    <>
                        <h2 className={classnames(css.labelReleasesHeader, loading && css.loading)}>
                            <Trans>Releases</Trans>
                        </h2>
                        <table className={classnames(css.labelReleasesTable, loading && css.loading)}>
                            <thead>
                                {view !== 'coversOnly' && (
                                    <tr className={css.sortRow}>
                                        {view !== 'textOnly' && <td />}
                                        <th className={css.leftSortParamaters}>
                                            <span
                                                onClick={() =>
                                                    updateSort(
                                                        `ARTIST ${orderBy.direction === 'DESC' ? 'ASC' : 'DESC'}`,
                                                    )
                                                }
                                                role='button'
                                            >
                                                <Trans>Artist</Trans>
                                                {orderBy.field === 'ARTIST' && (
                                                    <ChevronDown
                                                        className={classnames(
                                                            css.sort,
                                                            orderBy.direction === 'DESC' && css.desc,
                                                        )}
                                                    />
                                                )}
                                            </span>
                                            {' – '}
                                            <span
                                                onClick={() =>
                                                    updateSort(`TITLE ${orderBy.direction === 'DESC' ? 'ASC' : 'DESC'}`)
                                                }
                                                role='button'
                                            >
                                                <Trans>Title</Trans>
                                                {orderBy.field === 'TITLE' && (
                                                    <ChevronDown
                                                        className={classnames(
                                                            css.sort,
                                                            orderBy.direction === 'DESC' && css.desc,
                                                        )}
                                                    />
                                                )}
                                            </span>
                                            (
                                            <span
                                                onClick={() =>
                                                    updateSort(
                                                        `FORMAT ${orderBy.direction === 'DESC' ? 'ASC' : 'DESC'}`,
                                                    )
                                                }
                                                role='button'
                                            >
                                                <Trans>Format</Trans>
                                                {orderBy.field === 'FORMAT' && (
                                                    <ChevronDown
                                                        className={classnames(
                                                            css.sort,
                                                            orderBy.direction === 'DESC' && css.desc,
                                                        )}
                                                    />
                                                )}
                                            </span>
                                            )
                                        </th>
                                        <th />
                                        {view === 'textOnly' && (
                                            <>
                                                <th />
                                                <th />
                                            </>
                                        )}
                                        <th className={css.catno}>
                                            <span
                                                onClick={() =>
                                                    updateSort(`CATNO ${orderBy.direction === 'DESC' ? 'ASC' : 'DESC'}`)
                                                }
                                                role='button'
                                            >
                                                <Trans>Catalog Number</Trans>
                                                {orderBy.field === 'CATNO' && (
                                                    <ChevronDown
                                                        className={classnames(
                                                            css.sort,
                                                            orderBy.direction === 'DESC' && css.desc,
                                                        )}
                                                    />
                                                )}
                                            </span>
                                        </th>
                                        <th />
                                        <th className={css.year}>
                                            <span
                                                onClick={() =>
                                                    updateSort(`YEAR ${orderBy.direction === 'DESC' ? 'ASC' : 'DESC'}`)
                                                }
                                                role='button'
                                            >
                                                <Trans>Year</Trans>
                                                {orderBy.field === 'YEAR' && (
                                                    <ChevronDown
                                                        className={classnames(
                                                            css.sort,
                                                            orderBy.direction === 'DESC' && css.desc,
                                                        )}
                                                    />
                                                )}
                                            </span>
                                        </th>
                                        <td className={css.skittles} />
                                        <td className={css.actions} />
                                    </tr>
                                )}
                            </thead>
                            <tbody>
                                {label?.releases.releaseGroups.releaseGroupDescriptions?.map((group, index) =>
                                    view === 'coversOnly' ? (
                                        group?.keyRelease && (
                                            <ReleaseCard
                                                {...group.keyRelease}
                                                key={`${group.keyRelease.discogsId} ${index}`}
                                                versionCount={group.releaseCount}
                                                itemType={ItemType.Label}
                                                discogsId={discogsId}
                                            />
                                        )
                                    ) : (
                                        <ReleaseTable
                                            // @ts-expect-error - TODO return to type this correctly
                                            masterData={group?.keyRelease}
                                            versionCount={group?.releaseCount}
                                            view={view}
                                            creditCategory='Releases'
                                            artistId={discogsId}
                                            artistName=''
                                            itemType={ItemType.Label}
                                            groupingId={group?.groupingId}
                                            labelId={discogsId}
                                            labelCatalogNumber={group?.catalogNumberDisplayed}
                                            years={years}
                                            genres={genres}
                                            roles={roles}
                                            countries={countries}
                                            formats={formats}
                                            search={search}
                                            key={index}
                                        />
                                    ),
                                )}
                            </tbody>
                        </table>
                    </>
                ) : !loading ? (
                    <div className={css.noSearchResults}>
                        <Trans>0 results found when searching for &quot;{search}&quot;</Trans>
                    </div>
                ) : null}
                <div className={css.addLabelReleaseButton}>
                    <Trans>
                        <Link href={`https://www.discogs.com/release/add?from_label=${name}`}>
                            <Plus /> Add Release
                        </Link>
                    </Trans>
                </div>
                <div>
                    {totalCount && (
                        <Pager
                            currentPage={page}
                            perPage={perPage}
                            totalCount={totalRoleCount > 0 ? totalRoleCount : totalCount}
                            onChange={(newPage) => handlePageChange(page, newPage)}
                            title={i18n._(t`Label releases bottom pagination`)}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
