import { ReactElement } from 'react'
import { Title } from 'react-head'
import { t } from '@lingui/macro'

import { useLocalisation } from '../../lib/i18n'

type LabelTitleProps = {
    name: string
}

export function LabelTitle(props: LabelTitleProps): ReactElement {
    const { name } = props
    const { i18n } = useLocalisation()

    return <Title>{i18n._(t`${name} | Discogs`)}</Title>
}
