import { ReactElement, useState, useRef, useEffect } from 'react'
import { Trans } from '@lingui/macro'
import classnames from 'classnames'
import { Link } from '../../lib/components/link'
import { Header } from '../../lib/components/header'
import { Sites } from '../../lib/components/sites'
import { HeaderTable, Item } from '../../lib/components/header-table'
import { intersperse } from '../../lib/intersperse'

import { LabelImages } from '../label-images'

import { LabelHeaderFragment } from '../../api/types'

import css from './styles.css'
import { ShowMore } from '../../lib/components/show-more'

const truncateAt = 200

type Props = LabelHeaderFragment & {
    setIsHeaderExpanded: (key: boolean) => void
} & {
    altLabel?: {
        discogsId: number
        name: string
        siteUrl: string
    }
}

export function LabelHeader(props: Props): ReactElement {
    const {
        altLabel,
        contactInfo,
        manufacturerContact,
        manufacturerEUContact,
        name,
        parentLabel,
        profile,
        setIsHeaderExpanded,
        subLabels,
        urls,
    } = props

    const profileRef = useRef<HTMLDivElement>(null)
    const [shouldShowMoreProfile, setShouldShowMoreProfile] = useState(false)
    const [shouldShowMoreContact, setShouldShowMoreContact] = useState(false)
    const [lineCount, setLineCount] = useState(0)

    useEffect(
        function () {
            if (!profileRef.current) {
                return
            }
            const lineHeight = parseFloat(getComputedStyle(profileRef.current).lineHeight)
            const height = profileRef.current.offsetHeight
            const newLineCount = Math.round(height / lineHeight)
            setLineCount(newLineCount)
        },
        [profile],
    )

    const position = profileRef.current ? profileRef.current.offsetTop : 0
    const endsWithLineBreak = contactInfo.html.endsWith('<br>')

    let contactInfoLineBreaks = contactInfo.html.split('<br>').length - 1

    if (endsWithLineBreak) {
        contactInfoLineBreaks = contactInfo.html.split('<br>').length - 2
    }

    const hasContactInfo =
        contactInfo.html.trim() !== '' || manufacturerContact.trim() !== '' || manufacturerEUContact.trim() !== ''

    return (
        <Header title={name} images={<LabelImages {...props} />}>
            <HeaderTable>
                <Item name={<Trans>Profile</Trans>}>
                    {altLabel ? (
                        <Trans>
                            <b>DO NOT USE</b> Please use <Link href={altLabel.siteUrl}>{altLabel.name}</Link> instead.
                        </Trans>
                    ) : (
                        <div ref={profileRef} className={css.labelProfile}>
                            <p
                                className={shouldShowMoreProfile ? '' : css.hideContent}
                                dangerouslySetInnerHTML={{ __html: profile.html }}
                            />
                        </div>
                    )}
                    {profile.html.length > truncateAt || lineCount === 5 ? (
                        <ShowMore
                            isShowingMore={shouldShowMoreProfile}
                            onToggle={setShouldShowMoreProfile}
                            scroll
                            position={position}
                            setIsHeaderExpanded={setIsHeaderExpanded}
                        />
                    ) : null}
                </Item>
                {parentLabel && (
                    <Item name={<Trans>Parent Label</Trans>}>
                        <LabelLink {...parentLabel} />
                    </Item>
                )}
                {subLabels.length > 0 && (
                    <Item name={<Trans>Sublabels</Trans>}>
                        <SubLabels subLabels={subLabels} setIsHeaderExpanded={setIsHeaderExpanded} />
                    </Item>
                )}
                {hasContactInfo && (
                    <Item name={<Trans>Contact Info</Trans>}>
                        <div className={classnames(shouldShowMoreContact ? '' : css.hideContent)}>
                            <p className={css.contact} dangerouslySetInnerHTML={{ __html: contactInfo.html }} />
                            {manufacturerContact && (
                                <>
                                    <h2>
                                        <Trans>Manufacturer Contact</Trans>
                                    </h2>
                                    <p className={css.gpsr}>{manufacturerContact}</p>
                                </>
                            )}
                            {manufacturerEUContact && (
                                <>
                                    <h2>
                                        <Trans>Manufacturer EU Contact</Trans>
                                    </h2>
                                    <p className={css.gpsr}>{manufacturerEUContact}</p>
                                </>
                            )}
                        </div>
                        {contactInfoLineBreaks > 4 ||
                        contactInfo.html.length > truncateAt ||
                        manufacturerContact.length ||
                        manufacturerEUContact.length ? (
                            <ShowMore
                                isShowingMore={shouldShowMoreContact}
                                onToggle={setShouldShowMoreContact}
                                scroll
                                position={position}
                                setIsHeaderExpanded={setIsHeaderExpanded}
                            />
                        ) : null}
                    </Item>
                )}
                {urls.length > 0 && (
                    <Item name={<Trans>Links</Trans>}>
                        <Sites urls={urls} setIsHeaderExpanded={setIsHeaderExpanded} />
                    </Item>
                )}
            </HeaderTable>
        </Header>
    )
}

type LabelLinkProps = {
    name?: string
    siteUrl?: string
}

function LabelLink(props: LabelLinkProps) {
    const { name, siteUrl } = props

    if (!name) {
        return null
    }

    if (!siteUrl) {
        return <>{name}</>
    }

    return <Link href={siteUrl}>{name}</Link>
}

type SublabelProps = { subLabels: LabelHeaderFragment['subLabels']; setIsHeaderExpanded?: (key: boolean) => void }

function SubLabels(props: SublabelProps) {
    const { subLabels, setIsHeaderExpanded } = props

    const [shouldShowMore, setShouldShowMore] = useState(false)

    const truncateAt = 10

    const truncate = shouldShowMore ? subLabels.length : truncateAt
    const truncated = truncate < subLabels.length

    return (
        <>
            {intersperse(
                ', ',
                subLabels.slice(0, truncate).map((label) => label && <LabelLink key={label.discogsId} {...label} />),
            )}
            {!shouldShowMore && truncated && ', ...'}
            <br />
            {subLabels.length > truncateAt && (
                <ShowMore
                    isShowingMore={shouldShowMore}
                    onToggle={setShouldShowMore}
                    setIsHeaderExpanded={setIsHeaderExpanded}
                />
            )}
        </>
    )
}
